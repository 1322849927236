import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Block, Card, Image, Name, Button, Text, Desc, Margin } from './styled';
import Plugs from '../../Products/Info/Plugs';
import useI18nNavigation from '../../../hooks/use-i18n-navigation';
import useAvailablePlug from '../../../hooks/use-available-plug';
import useBasket from '../../../hooks/use-basket';
import Badge from './Badge';
import data from './data';
import pagesInfo from '../../../domain/pages-info';
import useLocalizedCurrency from '../../../hooks/use-localized-currency';

function GroupCard({ group, cardKey, large }) {
  const items = useSelector((state) => state.items.list);
  const { addItem } = useBasket();
  const navigate = useI18nNavigation();
  const getAvailablePlug = useAvailablePlug();

  const title = group.getTitle();
  const { url } = Object.values(pagesInfo).find(
    (item) => item.title.toLowerCase() === title.toLowerCase()
  );
  const subTitle = group.getSubtitle();
  const price = group.getCurrentPrice();
  const currency = useLocalizedCurrency();
  const img = group.getGroupFullImage();
  const isInStock = group.isInStock();
  const description = group.getDescription();
  const isCartridge = group.isCartridgeVariant();

  const [currentPlug, setActivePlug] = useState(null);
  const onPlugChange = (item) => {
    group.onChange(item);
    setActivePlug(item);
  };

  const onClick = () => {
    navigate(`/${url}`);
  };

  useEffect(() => {
    if (group.getGroupType()) {
      const availableList = group
        .getGroup()
        .filter((v) => v.available === true);

      if (!currentPlug) {
        const plug = getAvailablePlug(availableList);

        if (plug) {
          onPlugChange(plug);
        }
      }
    }
  }, [items]);

  const [error, setError] = useState(false);

  const onAddItem = () => {
    if (!group.currentItem()) {
      setError(true);
      return;
    }

    setError(false);
    addItem(group.currentItem(), true);
  };

  const isEmptyDesc = description === '';

  const isBadgeVisible = isCartridge && data.find((v) => v.name === title);
  const isBadgeNew =
    isCartridge && data.find((v) => v.name === title && v.new === true);

  return (
    <Block key={cardKey} large={large}>
      <Card large={large}>
        {isBadgeVisible && <Badge isNew={isBadgeNew} />}
        <Image onClick={onClick}>
          <img src={img} alt="preview" />
        </Image>
        {group.getGroupType() && (
          <Plugs
            productName={title}
            active={currentPlug ? currentPlug.title : ''}
            setPlug={onPlugChange}
            list={group.getGroup()}
            shop
            error={error}
          />
        )}
        <Text>{subTitle}</Text>
        {large ? (
          <>
            {!isEmptyDesc && (
              <Desc large={large}>
                <FormattedMessage id={description} />
              </Desc>
            )}
            <Name>{title}</Name>
          </>
        ) : (
          <>
            {!isEmptyDesc && (
              <Desc>
                <FormattedMessage id={description} />
              </Desc>
            )}
            <Name>{title}</Name>
          </>
        )}
        <Text>{`${currency} ${price}`}</Text>
        {isEmptyDesc && <Margin />}
        <Button disabled={!isInStock} onClick={onAddItem}>
          <FormattedMessage
            id={
              isInStock
                ? 'shop.card.add_to_cart'
                : 'shop.card.add_to_cart.out_of_stock'
            }
          />
        </Button>
      </Card>
    </Block>
  );
}

export default GroupCard;

GroupCard.propTypes = {
  group: PropTypes.objectOf(PropTypes.any).isRequired,
  cardKey: PropTypes.string.isRequired,
  large: PropTypes.bool,
};

GroupCard.defaultProps = {
  large: false,
};
