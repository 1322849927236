import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Wrap, Title, Link } from './styled';

const url =
  'https://support.evapolar.com/hc/en-us/articles/360017008399-How-to-choose-a-cartridge-for-your-Evapolar';

function Badge({ isNew }) {
  return (
    <Wrap>
      <Title>
        <FormattedMessage
          id={isNew ? 'shop.badge.title.new' : 'shop.badge.title.old'}
        />
        <br />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link href={url}>
          <FormattedMessage id="shop.badge.link" />
        </Link>
      </Title>
    </Wrap>
  );
}

Badge.propTypes = {
  isNew: PropTypes.bool,
};

Badge.defaultProps = {
  isNew: false,
};

export default Badge;
