import styled from 'styled-components';
import { colors } from '../../../../ui/common';

export const Wrap = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 105px;
  background-color: ${colors.accent};
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 12px;
  z-index: 3;
  line-height: 130%;
`;

export const Title = styled.div`
  white-space: pre-wrap;
  display: inline;
  font-weight: bold;
  opacity: 0.9;
`;

export const Link = styled.a`
  font-weight: normal;
  opacity: 0.9;
  color: #fff;
`;
