import has from 'lodash/has';
import forOwn from 'lodash/forOwn';
import ShopGroup from './shop-group';
import DeviceShopGroup from './device-shop-group';
import {
  getItemColorAttribute,
  getItemPrice,
  getItemRemaindersQuantity,
  hasAttributes,
  isItemLimited,
} from '../utils/api/items';

const sortByCartridgeVariant = (a, b) => {
  const a1 = a.isCartridgeVariant() || 0;
  const b1 = b.isCartridgeVariant() || 0;

  return a1 - b1;
};

const sortByOrderingNumber = (a, b) => {
  const a1 = a.getOrderingNumber() || 0;
  const b1 = b.getOrderingNumber() || 0;

  return a1 - b1;
};

const sortByStock = (a, b) => {
  const a1 = a.isInStock() ? 0 : 1;
  const b1 = b.isInStock() ? 0 : 1;

  return a1 - b1;
};

const sortByLimited = (a, b) => {
  const a1 = a.isLimited() ? 0 : 1;
  const b1 = b.isLimited() ? 0 : 1;

  return a1 - b1;
};

const sortDevicesGroups = (groups) => {
  groups.sort(sortByOrderingNumber);
  groups.sort(sortByStock);
  groups.sort(sortByLimited);
  groups.sort(sortByCartridgeVariant);
};

const sortAccessoriesGroups = (groups) => {
  groups.sort(sortByOrderingNumber);
  groups.sort(sortByStock);
};

function groupDevicesByColor(items, sortedPlugs) {
  const groupsDict = {};
  items.forEach((item) => {
    if (
      (isItemLimited(item) && getItemRemaindersQuantity(item) === 0) ||
      getItemPrice(item) === null ||
      !hasAttributes(item)
    ) {
      return;
    }

    const color = getItemColorAttribute(item);
    if (!has(groupsDict, color)) {
      groupsDict[color] = [];
    }

    groupsDict[color].push(item);
  });
  const groups = [];
  forOwn(groupsDict, (grItems) => {
    groups.push(new DeviceShopGroup(grItems, sortedPlugs));
  });
  items.forEach((item) => {
    if (hasAttributes(item) || getItemPrice(item) === null) {
      return;
    }
    const group = new ShopGroup([item]);
    groups.push(group);
  });
  sortDevicesGroups(groups);
  return groups;
}

function groupAccessories(items) {
  const groups = [];
  items.forEach((item) => {
    if (hasAttributes(item) || getItemPrice(item) === null) {
      return;
    }
    const group = new ShopGroup([item]);
    groups.push(group);
  });
  sortAccessoriesGroups(groups);
  return groups;
}

export { groupAccessories, groupDevicesByColor };
