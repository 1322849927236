import styled from 'styled-components';
import { media, colors, mediaMax } from '../../ui/common';

export const Wrap = styled.div`
  margin-bottom: 16px;
  margin: 0 -15px;
  box-sizing: border-box;
  ${media.phone`
    margin: 0;
  `}
`;

export const List = styled.div`
  display: flex;
  padding: 8px 0 169px;
  width: 100vw;
  overflow: auto;
  ${media.phone`
    margin: 0 -15px;
    padding: 25px 0 110px;
  `}
  ${media.medium`
    width: auto;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 12px 0 100px;
  `}
`;

export const Row = styled.div`
  padding: 8px 15px;
  background: white;
  ${media.phone`
    padding: 30px 36px;
    background: transparent;
    margin: 0;
    width: 50%;
  `}
  ${media.medium`
    display: flex;
    padding: 0;
    width: 67%;
  `}
`;

export const Card = styled.div`
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-sizing: border-box;
  background: ${(props) => (props.cover ? props.cover : '#DCDCDC')};
  ${media.phone`
    overflow: hidden;
    color: white;
    min-height: 252px;
    display: flex;
    justify-content: space-between;
  `}
  ${media.medium`
    min-height: 286px;
  `}
}
`;
export const Cell = styled.div`
  color: ${colors.color05};

  h3 {
    font-size: 40px;
    display: inline-block;
    color: inherit;
    font-weight: normal;
    margin: 14px 0;
  }
  ${media.phone`
    color: white;
    margin: auto;
    padding: 8px 0;
    box-sizing: border-box;
    h3 {
      margin: 0;
      display: block;
      line-height: 1.3;
    }
  `}
  ${media.medium`
    text-align: center;
    text-align: center;
    padding: 0 10px;
    width: 50%;
  `}
`;

export const Model = styled.div`
  text-align: center;
  display: inline-block;
  font-size: 24px;
  padding: 0 8px;
  ${media.phone`
    padding: 0;
  `}
`;
export const Text = styled.div`
  font-size: 18px;
  opacity: 0.9;
  line-height: 1.65;
  color: ${colors.color01};
  ${media.phone`
    color: white;
    font-size: 14px;
    line-height: 1.8;
  `};
  ${media.medium`
    font-size: 18px;
    text-align: left;
  `}
`;
export const Image = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: ${(props) => (props.cover ? props.cover : '#DCDCDC')};
  img {
    display: block;
    height: 100%;
  }
  ${media.phone`
    width: 50%;
    margin: 0;
    order: 1;
  `}
  ${media.medium`
      width: 33%;
  `}
  ${mediaMax.phone`
    overflow: hidden;
  `}
`;
export const PreviewImage = styled.div`
  width: 333px;
`;

export const H1 = styled.h1`
  font-size: 40px;
  display: inline-block;
  color: inherit;
  font-weight: normal;
  margin: 14px 0;
`;
