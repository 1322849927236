import {
  getItemMediumImage,
  getItemFullImage,
  getItemPrice,
  getItemPriceCurrency,
  getItemRemaindersQuantity,
  getItemName,
  getItemDescription,
  isItemLimited,
  getItemOrderingNumber,
} from '../utils/api/items';
import { isCartridgeVariant } from '../utils/slug-checks';

class ShopGroup {
  constructor(items) {
    this.items = items;
    this.groupType = '';
  }

  getTitle() {
    return getItemName(this.items[0]);
  }

  // eslint-disable-next-line class-methods-use-this
  getSubtitle() {
    return '';
  }

  getGroupImage() {
    return getItemMediumImage(this.items[0]);
  }

  getGroupFullImage() {
    return getItemFullImage(this.items[0]);
  }

  getQuantity() {
    return getItemRemaindersQuantity(this.items[0]);
  }

  getDescription() {
    return getItemDescription(this.items[0]);
  }

  isInStock() {
    return this.getQuantity() > 0;
  }

  getCurrentPrice() {
    return getItemPrice(this.items[0]);
  }

  getCurrentPriceCurrency() {
    return getItemPriceCurrency(this.items[0]);
  }

  getGroupType() {
    return this.groupType;
  }

  currentItem() {
    return this.items[0];
  }

  getFirstItem() {
    return this.items[0];
  }

  isLimited() {
    return isItemLimited(this.getFirstItem());
  }

  getOrderingNumber() {
    return getItemOrderingNumber(this.getFirstItem());
  }

  isCartridgeVariant() {
    return isCartridgeVariant(this.getFirstItem());
  }
}

export default ShopGroup;
