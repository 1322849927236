import React from 'react';
import styled from 'styled-components';
import { DotLoader } from 'react-spinners';
import { mediaMax } from '../../ui/common';

const Wrap = styled.div`
  width: 100%;
  height: 667px;
  ${mediaMax.tablet`
    height: 697px;
  `}
`;

const WrapAnim = styled.div`
  position: relative;
  left: calc(50% - 60px / 2);
  top: calc(50% - 60px / 2);
`;

function EmptyList() {
  return (
    <Wrap>
      <WrapAnim>
        <DotLoader color="green" />
      </WrapAnim>
    </Wrap>
  );
}

export default EmptyList;
