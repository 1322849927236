import {
  getItemColorAttribute,
  getItemColorTitleByShort,
  getItemPlug,
  getItemRemaindersQuantity,
  getItemDescription,
} from '../utils/api/items';
import ShopGroup from './shop-group';

/**
 * Devices are grouped by colors.
 */
class DeviceShopGroup extends ShopGroup {
  constructor(items, sortedPlugs) {
    super(items);
    this.currentItemIdx = -1;
    this.items.sort((a, b) => {
      const aPlug = getItemPlug(a);
      const bPlug = getItemPlug(b);
      if (aPlug > bPlug) {
        return 1;
      }
      if (aPlug < bPlug) {
        return -1;
      }
      return 0;
    });
    if (!getItemPlug(this.items[0])) {
      this.groupType = '';
      this.plugGroup = [];
      this.currentItemIdx = 0;
    } else {
      this.groupType = 'plug';
      this.plugGroup = this.items
        .sort((a, b) => sortedPlugs.indexOf(a) - sortedPlugs.indexOf(b))
        .map((item) => ({
          title: getItemPlug(item),
          available: getItemRemaindersQuantity(item) > 0,
        }));
    }
  }

  getSubtitle() {
    const item = this.items[0];
    const color = getItemColorAttribute(item);
    return getItemColorTitleByShort(item, color);
  }

  isInStock() {
    let totalQuantity = 0;
    this.items.forEach((item) => {
      totalQuantity += getItemRemaindersQuantity(item);
    });
    return totalQuantity > 0;
  }

  getQuantity() {
    if (this.currentItemIdx < 0) {
      return 0;
    }
    return getItemRemaindersQuantity(this.currentItem());
  }

  onChange(newCurrentElement) {
    this.currentItemIdx = this.plugGroup.indexOf(newCurrentElement);
  }

  getGroupType() {
    return this.groupType;
  }

  getGroup() {
    return this.plugGroup;
  }

  getDescription() {
    return getItemDescription(this.currentItem());
  }

  currentItem() {
    /* return for non group */
    if (this.groupType === '') {
      return this.getFirstItem();
    }

    /* if it has group */
    if (this.plugGroup[this.currentItemIdx]) {
      const plugCountry = this.plugGroup[this.currentItemIdx].title;

      if (plugCountry !== null)
        return this.items.find(
          (v) => v.attributes && v.attributes.plug === plugCountry
        );
    }
    return null;
  }
}

export default DeviceShopGroup;
