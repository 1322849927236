import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Container } from '../ui/common';
import NavBar from '../components/Navbar';
import Blog from '../components/Blog';
import Footer from '../components/Footer';
import ShopItems from '../components/Shop/Items';

const Section = styled.section`
  margin-top: 150px;
  overflow: hidden;
`;

function ShopPage({ pageContext }) {
  return (
    <>
      <main>
        <NavBar />
        <Section>
          <Container>
            <ShopItems />
          </Container>
        </Section>
        <Blog list={pageContext.blogList} />
      </main>
      <Footer />
    </>
  );
}
ShopPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
};

export default ShopPage;
