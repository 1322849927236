export default {
  evachill: {
    href: 'evachill',
    title: 'shop.evachill.title',
    model: 'EV-500',
    text: 'shop.evachill.desc',
    cover: '#00BBB3',
  },
  evasmart: {
    href: 'evasmart',
    title: 'shop.evasmart.title',
    model: 'EV-3000',
    text: 'shop.evasmart.desc',
    cover: '#262A2A',
  },
  evalight: {
    href: 'evalight',
    title: 'shop.evalight.title',
    model: 'EV-1500',
    text: 'shop.evalight.desc',
    cover: '#465C9A',
  },
  evapure: {
    href: 'evapure',
    title: 'shop.evapure.title',
    model: 'EP-1000',
    text: 'shop.evapure.desc',
    cover: '#5F6EFF',
  },
  accessories: {
    href: 'accessories',
    title: 'shop.accessories.title',
    model: '',
    text: 'shop.accessories.desc',
  },
};
