export default [
  {
    name: 'evaLIGHT Cartridge',
    new: false,
  },
  {
    name: 'evaLIGHT',
    new: false,
  },
  {
    name: 'evaLIGHT plus Cartridge',
    new: true,
  },
];
