import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import GroupCard from './GroupCard';
import {
  Wrap,
  List,
  Card,
  Cell,
  Model,
  Text,
  Image,
  Row,
  H1,
  PreviewImage,
} from './styled';
import genKey from '../../utils/gen-key';
import { selectSortedPlugs } from '../../store/selectors/countries';
import EmptyList from './EmptyList';

const imagesQuery = graphql`
  query {
    evachill: file(relativePath: { eq: "shop/evaCHILL@2x.png" }) {
      ...imageFragmentBlur
    }
    evalight: file(relativePath: { eq: "shop/evaLIGHT@2x.png" }) {
      ...imageFragmentBlur
    }
    evasmart: file(relativePath: { eq: "shop/evaSMART@2x.png" }) {
      ...imageFragmentBlur
    }
    evapure: file(relativePath: { eq: "shop/evaPURE@2x.png" }) {
      ...imageFragmentBlur
    }
    accessories: file(relativePath: { eq: "shop/Accessories@2x.png" }) {
      ...imageFragmentBlur
    }
  }
`;

function Shop({ value, iter }) {
  const intl = useIntl();
  const { list, cover, title, text, model, grouper, href } = value;
  const sortedPlugs = useSelector(selectSortedPlugs);
  const groups = grouper(list, sortedPlugs);

  const passTitle = {
    dangerouslySetInnerHTML: { __html: intl.formatMessage({ id: title }) },
  };

  return (
    <Wrap id={href}>
      <Card cover={cover}>
        <Image cover={cover}>
          <StaticQuery
            query={imagesQuery}
            render={(data) => (
              <PreviewImage>
                <GatsbyImage
                  image={data[href].childImageSharp.gatsbyImageData}
                  alt=""
                />
              </PreviewImage>
            )}
          />
        </Image>
        <Row>
          <Cell>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {iter === 0 ? <H1 {...passTitle} /> : <H1 {...passTitle} />}
            <br />
            <Model>{model}</Model>
          </Cell>
          <Cell>
            <Text>
              <FormattedMessage id={text} />
            </Text>
          </Cell>
        </Row>
      </Card>
      {groups.length === 0 ? (
        <EmptyList />
      ) : (
        <List>
          {groups.map((group) => (
            <GroupCard group={group} cardKey={genKey()} key={genKey()} />
          ))}
        </List>
      )}
    </Wrap>
  );
}

export default Shop;

Shop.propTypes = {
  value: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  iter: PropTypes.number.isRequired,
};
