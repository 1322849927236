import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Shop from '../index';
import {
  isCartridgeVariant,
  isEvaChill,
  isEvaPure,
  isEvaLightPlus,
  isEvaSmart,
} from '../../../utils/slug-checks';
import data from '../data';
import genKey from '../../../utils/gen-key';
import {
  groupDevicesByColor,
  groupAccessories,
} from '../../../domain/shop-groupers';
import {
  mixpanelTrack,
  onViewAllProductsDataLayer,
} from '../../../utils/datalayer/dataLayer';
import { getItemName } from '../../../utils/api/items';
import filterData from './data';

export default function Items() {
  const allItems = useSelector((state) => state.items.list);

  useEffect(() => {
    if (allItems.length > 0) {
      mixpanelTrack('main_shop_new');
      onViewAllProductsDataLayer(allItems, 'shop');
    }
  }, [allItems]);

  const items = [
    {
      ...data.evachill,
      list: allItems.filter((item) => {
        if (isEvaChill(item)) return true;

        if (isCartridgeVariant(item)) {
          const name = getItemName(item);

          return filterData.evachill.some((v) => v === name);
        }

        return false;
      }),
      grouper: groupDevicesByColor,
    },
    {
      ...data.evapure,
      list: allItems.filter((item) => {
        if (isEvaPure(item)) return true;
        return false;
      }),
      grouper: groupDevicesByColor,
    },
    {
      ...data.evalight,
      list: allItems.filter((item) => {
        if (isEvaLightPlus(item)) return true;

        if (isCartridgeVariant(item)) {
          const name = getItemName(item);

          return filterData.evalight.some((v) => v === name);
        }

        return false;
      }),
      grouper: groupDevicesByColor,
    },
    {
      ...data.evasmart,
      list: allItems.filter((item) => {
        if (isEvaSmart(item)) return true;

        if (isCartridgeVariant(item)) {
          const name = getItemName(item);

          return filterData.evasmart.some((v) => v === name);
        }

        return false;
      }),
      grouper: groupDevicesByColor,
    },
    {
      ...data.accessories,
      list: allItems.filter((item) => {
        const name = getItemName(item);

        return filterData.accessories.some((v) => v === name);
      }),
      grouper: groupAccessories,
    },
  ];

  return (
    <>
      {items.map((value, i) => (
        <Shop key={genKey()} value={value} iter={i} />
      ))}
    </>
  );
}
