import styled from 'styled-components';
import { media, colors, mediaMax } from '../../../ui/common';
import { secondary } from '../../../ui/buttonDefault';

export const Block = styled.div`
  margin: 0;
  box-sizing: border-box;
  padding: 0 15px;
  ${media.medium`
    padding: 15px;
    width: 33.33%;
  `}
  ${(props) => props.large && 'flex-basis: calc(100% / 2)'};
`;
export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 252px;
  height: 100%;
  background: ${colors.color10};
  border-radius: 4px;
  text-align: center;
  padding: 16px 16px;
  box-sizing: border-box;
  ${media.phone`
    width: 270px;
  `}
  ${media.medium`
    width: 100%;
  `}
  ${(props) =>
    props.large &&
    `
    padding-bottom: 71px;
    ${mediaMax.medium`
      padding-bottom: 32px;
    `};
  `}
`;
export const Button = styled.button`
  ${secondary};
  display: block;
  margin: 22px auto 16px;
`;
export const Image = styled.div`
  width: 210px;
  margin: 0 auto;
  img {
    max-height: 100%;
    max-width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin: 12px 0;
  color: ${colors.color05};
`;
export const Text = styled.div`
  line-height: 1.75;
  font-weight: 300;
  font-size: 13px;
  color: ${colors.color01};
`;
export const Desc = styled.div`
  margin-top: auto;
  margin-bottom: 10px;
  line-height: 1.75;
  font-weight: 300;
  font-size: 13px;
  color: ${colors.color01};
  transform: translateY(5px);
  ${(props) =>
    props.large &&
    `
    transform: translateY(0px);
    padding-top: 29px;
    margin-bottom: 0px;
    ${mediaMax.medium`
      padding-top: 21px;
    `};
  `}
`;
export const Margin = styled.div`
  margin-top: auto;
`;
