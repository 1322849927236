import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
import styled from 'styled-components';
import { media } from '../ui/common';
import { colors } from '../../../ui/common';
import { Wrap, Title } from './styled';

const Bar = styled.div`
  display: flex;
  margin: 0 -8px;
  ${(props) => (props.shop ? 'justify-content: center;' : '')};
`;
const Button = styled.button`
  white-space: nowrap;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0 8px;
  color: ${({ active, error }) => {
    if (active) return colors.color05;
    return error ? '#D23F36' : colors.color01;
  }};
  transition: 0.15s ease-out;
  &:hover {
    color: ${(props) => (props.error ? colors.error : colors.color05)};
  }
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
  ${media.laptop`
  `};
`;
const Dot = styled.div`
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  border: 1px solid
    ${(props) =>
      props.active || props.error ? colors.color12 : colors.color09};
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 24px;
    height: 24px;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    background: ${(props) => (props.active ? colors.color13 : 'transparent')};
    transition: 0.15s ease-out;
  }
  svg {
    color: ${(props) => {
      if (props.active) return 'white';
      return props.error ? colors.error : colors.color05;
    }};
  }
  &:hover:before {
    background: rgba(2, 112, 109, 0.1);
  }
  &:hover {
    border: 1px solid ${colors.color12};
  }
`;
const Text = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: currentColor;
  ${(props) => props.shop && `display: block; padding-top: 5px;`};
`;
const TextWrap = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

function Plugs({ shop, list, active, setPlug, error }) {
  return (
    <Wrap>
      {!shop && (
        <Title>
          <FormattedMessage id="product.title.plug" />
          {active}
        </Title>
      )}
      <Bar shop={shop}>
        {list.map((plug) => {
          const isShowError = plug.available && error;

          return (
            <Button
              type="button"
              key={plug.title}
              disabled={!plug.available}
              onClick={() => setPlug(plug)}
              active={active === plug.title}
              error={isShowError}
            >
              <Dot error={isShowError} active={active === plug.title}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="30"
                  hanging="30"
                >
                  {plug.title === 'AU' && (
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.828 10.707l-2.121 2.121M19.949 12.829l-2.121-2.122"
                    />
                  )}
                  {plug.title === 'EU' && (
                    <g>
                      <circle cx="11.5" cy="14.5" r="1.5" fill="currentColor" />
                      <circle cx="18.5" cy="14.5" r="1.5" fill="currentColor" />
                    </g>
                  )}
                  {plug.title === 'UK' && (
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.5 14.5v-4M9.5 18.5h4M17.5 18.5h4"
                    />
                  )}
                  {plug.title === 'US' && (
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.5 17.5v-5M18.5 17.5v-5"
                    />
                  )}
                  {plug.title === 'EU/UK' && (
                    <path
                      className="st0"
                      d="M11.25,10.8c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4
	                      C9.85,11.4,10.45,10.8,11.25,10.8z M18.65,10.8c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4
	                      C17.25,11.4,17.85,10.8,18.65,10.8z M14.95,15.5c-0.3,0-0.5-0.2-0.5-0.5v-3.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V15
	                      C15.45,15.3,15.25,15.5,14.95,15.5z M8.95,18.7c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.3,0.5-0.5,0.5h-3.7
	                      C9.15,19.2,8.95,18.9,8.95,18.7z M16.35,18.7c0-0.3,0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-3.8
	                      C16.55,19.2,16.35,18.9,16.35,18.7z"
                      fill="currentColor"
                    />
                  )}
                </svg>
              </Dot>
              <TextWrap>
                <Text shop={shop}>{plug.title}</Text>
              </TextWrap>
            </Button>
          );
        })}
      </Bar>
    </Wrap>
  );
}

export default Plugs;

Plugs.propTypes = {
  list: PropType.arrayOf(PropType.object).isRequired,
  active: PropType.string.isRequired,
  setPlug: PropType.func.isRequired,
  shop: PropType.bool,
  error: PropType.bool,
};

Plugs.defaultProps = {
  shop: false,
  error: false,
};
