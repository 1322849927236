const pagesInfo = {
  evapure: {
    productId: 16,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaPURE-thumbnail-530x365.png',
    url: 'evapure',
    title: 'evaPURE',
    desc: 'page.evapure.desc',
    about: 'page.evapure.about',
    about1: 'page.evapure.about1',
    hasRating: false,
    heading: 'evaPURE',
  },
  evasmart: {
    productId: 2,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaSMART_White-thumbnail-530x365.png',
    url: 'evasmart',
    title: 'evaSMART',
    desc: 'page.evasmart.desc',
    about: 'page.evasmart.about',
    hasRating: true,
  },
  evalight: {
    productId: 11,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaLIGHTplus_White-thumbnail-530x365.png',
    url: 'evalight',
    title: 'evaLIGHT plus',
    desc: 'page.evalight.desc',
    about: 'page.evalight.about',
    hasRating: true,
  },
  evachill: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evachill',
    title: 'evaCHILL',
    desc: 'page.evachill.desc',
    about: 'page.evachill.about',
    hasRating: true,
  },
  evabottle: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evabottle',
    title: 'evaBOTTLE',
    heading: 'evabottle.heading',
    desc: 'page.evabottle.desc',
    about: 'page.evabottle.about',
    hasRating: false,
  },
  evaaroma: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evaaroma',
    title: 'evaAROMA set',
    heading: 'evaaroma.heading',
    desc: 'page.evaaroma.desc',
    about: 'page.evaaroma.about',
    hasRating: false,
  },
  evabag: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evabag',
    title: 'evaBAG',
    heading: 'evabag.heading',
    desc: 'page.evabag.desc',
    about: 'page.evabag.about',
    hasRating: false,
  },
  evabank: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evabank',
    title: 'evaBANK',
    heading: 'evabank.heading',
    desc: 'page.evabank.desc',
    about: 'page.evabank.about',
    hasRating: false,
  },
  evachillCatridge: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evachill-cartridge',
    title: 'evaCHILL Cartridge',
    heading: 'evachill-cartridge.heading',
    desc: 'page.evachillCartridge.desc',
    about: 'page.evachillCartridge.about',
    hasRating: false,
  },
  evalightCatridge: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evalight-cartridge',
    title: 'evaLIGHT Cartridge',
    heading: 'evalight-cartridge.heading',
    desc: 'page.evalightCartridge.desc',
    about: 'page.evalightCartridge.about',
    hasRating: false,
  },
  evalightPlusCatridge: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evalight-plus-cartridge',
    title: 'evaLIGHT plus Cartridge',
    heading: 'evalight-plus-cartridge.heading',
    desc: 'page.evalightPlusCartridge.desc',
    about: 'page.evalightPlusCartridge.about',
    hasRating: false,
  },
  evaSmartCatridge: {
    productId: 8,
    image:
      'https://production-eva.s3.amazonaws.com/__sized__/images/evaCHILL_Grey-thumbnail-530x365.png',
    url: 'evasmart-cartridge',
    title: 'evaSMART Cartridge',
    heading: 'evasmart-cartridge.heading',
    desc: 'page.evasmartCartridge.desc',
    about: 'page.evasmartCartridge.about',
    hasRating: false,
  },
};

export default pagesInfo;
